import React from 'react'
import { useSearchAccountsMutation } from '../../../services'
import { Form } from 'react-final-form'
import AccountsListSearchBlock from './components/AccountsListSearchBlock/AccountsListSearchBlock'
import AccountsListTableBlock from './components/AccountsListTableBlock/AccountsListTableBlock'
import { withPermissionsDropdown } from '../../../hoc'
import { Button, Card, Container } from '@mui/material'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import { storageGet } from '../../../utils/storage'
import { useRouter } from '../../../routes/hooks'
import { appRoute } from '../../../constants'

interface Props {}

const AccountsList = ({}: Props) => {
  const [searchAccounts, { data: searchAccountsData, isLoading: searchAccountsIsLoading, error }] = useSearchAccountsMutation()
  const companyType = storageGet('companyType')
  const router = useRouter()
  const handleSubmit = (values: any) => {
    const jsonBranchList = values?.branch?.map((item: any) => ({ BranchID: item?.BranchID })) || []
    searchAccounts({
      uspAccountSearch: {
        searchString: values?.searchString || null,
        jsonBranchList: jsonBranchList || [],
        accountStatusOption: values?.accountStatusOption || null,
        coaID: null,
      },
    })
  }

  return (
    <Container
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CustomBreadcrumbs
        heading='Accounts'
        links={[
          {
            name: 'List',
          },
        ]}
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
        action={
          <>
            {companyType == 'Finance' ? (
              <Button onClick={() => router.push(appRoute.AccountsLoanPurchase)} variant='contained'>
                Purchase
              </Button>
            ) : (
              <></>
            )}
          </>
        }
      />

      <Card
        sx={{
          display: { md: 'flex' },
          flexDirection: { md: 'column' },
        }}
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={{
            searchString: null,
            accountStatusOption: 2,
          }} // require to avoid first debounce search
        >
          {({ handleSubmit, form: { submit } }: any) => (
            <form onSubmit={handleSubmit}>
              <AccountsListSearchBlock submit={submit} error={error} />
              <AccountsListTableBlock loading={searchAccountsIsLoading} searchAccountsData={searchAccountsData} />
            </form>
          )}
        </Form>
      </Card>
    </Container>
  )
}

export default withPermissionsDropdown(AccountsList)
