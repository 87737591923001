import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, FormControlLabel, Grid, Switch, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { CheckboxField } from '../../../../../../ui'
import { Field } from 'react-final-form'

const classes = generateUtilityClasses('Grid', ['root'])

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {},
}))

interface Props {}

export const CollectionsForm = ({}: Props) => {
  return (
    <>
      <CardHeader title='Collections' />
      <Grid container spacing={2} padding={3}>
        <Grid item xs={3}>
          <Field name='AccessCollections'>
            {(props) => (
              <FormControlLabel
                sx={{ width: '100%' }}
                control={
                  <Switch
                    name={props.input.name}
                    checked={props.input.value}
                    onChange={(e) => {
                      props.input.onChange(e.target.checked)
                    }}
                  />
                }
                labelPlacement='end'
                label='Access Collections'
              />
            )}
          </Field>
        </Grid>
      </Grid>
    </>
  )
}

export default CollectionsForm
