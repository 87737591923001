import TextField from '@mui/material/TextField'
import { Field } from 'react-final-form'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { formatDateTime } from '../../utils/dateUtils'
import { FormHelperText } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'

interface Props {
  name: string
  label: string
  fullWidth?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  disableFuture?: boolean
  inputFormat?: string
  defaultValue?: string
  required?: boolean
  disableManualUserInput?: boolean
  onDateChange?: () => void
  onDropdownFormChange?: () => void
  validate?: (value: any) => any
  [x: string]: any
}
const DateTimeField = ({
  name,
  label,
  onDropdownFormChange,
  fullWidth,
  inputFormat = 'MM/DD/YYYY hh:mm A',
  disableFuture,
  defaultValue,
  disablePast,
  onDateChange,
  variant,
  required,
  disableManualUserInput,
  validate,
  ...props
}: Props) => {
  const isInvalidDate = (date: dayjs.Dayjs | null) => {
    if (!date) {
      return false
    }
    const now = dayjs()
    if (disablePast && date.isBefore(now, 'day')) {
      return true
    }
    if (disableFuture && date.isAfter(now, 'day')) {
      return true
    }
    return false
  }

  return (
    <Field name={name} validate={validate} defaultValue={defaultValue}>
      {({ input, meta: { error, touched } }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              onChange={(date) => {
                if (!isInvalidDate(date as any)) {
                  console.log(date)
                  if (onDropdownFormChange) {
                    onDropdownFormChange()
                  }
                  input.onChange(date)
                  input.onBlur()

                  // Call the provided onDateChange prop if disableManualUserInput is passed in
                  // so user can manually select a date from datepicker
                  if (onDateChange) {
                    onDateChange()
                  }
                }
              }}
              label={label}
              disableFuture={disableFuture}
              disablePast={disablePast}
              value={dayjs(input.value)}
              {...props}
            />
            {touched && error && (
              <FormHelperText id={`${name}-helper-text`} error={touched && error ? true : false}>
                {error}
              </FormHelperText>
            )}
          </LocalizationProvider>
        )
      }}
    </Field>
  )
}

export default DateTimeField
