import React, { Dispatch, SetStateAction } from 'react'
import { UspAccountSearchResult } from '../../../../../models'
import { storageGet, storageSet } from '../../../../../utils/storage'
import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  generateUtilityClasses,
} from '@mui/material'
import { styled } from '@mui/system'
import { visuallyHidden } from '@mui/utils'
import { getComparator, stableSort } from '../../../../../utils/sorting'
import { convertDateToDisplay } from '../../../../../utils/dateUtils'
import { appRoute } from '../../../../../constants'
import { useNavigate } from 'react-router-dom'

interface Props {
  searchAccountsData: UspAccountSearchResult[]
  loading: boolean
  selected: number[]
  setSelected: Dispatch<SetStateAction<number[]>>
}
const LoanPurchaseTableBlock = ({ searchAccountsData, loading, selected, setSelected }: Props) => {
  const initialOrder = (storageGet('accounts_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('accounts_order_by') || 'StockNumber') as keyof UspAccountSearchResult
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof UspAccountSearchResult>(initialOrderBy)
  const navigate = useNavigate()

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)
  const paginatedData = searchAccountsData

  // Handle page change
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleSelect = (id: number | null) => {
    if (id === null) return

    const newSelected = selected.includes(id) ? selected.filter((item) => item !== id) : [...selected, id]

    setSelected(newSelected)
  }

  // Handle rows per page change
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const handleEdit = (dealID: string) => {
    navigate(appRoute?.AccountsOverview.replace(':dealID', dealID as string))
  }

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspAccountSearchResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('accounts_order', isAsc ? 'desc' : 'asc')
    storageSet('accounts_order_by', property)
  }

  const createSortHandler = (property: keyof UspAccountSearchResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = searchAccountsData?.map((item) => item.DealID) as number[]
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  return (
    <>
      <TableContainer sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 150px)' }}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell padding='checkbox'>
                <Checkbox
                  indeterminate={selected?.length > 0 && selected?.length < searchAccountsData?.length}
                  checked={searchAccountsData?.length > 0 && selected?.length === searchAccountsData?.length}
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell sortDirection={orderBy === 'StockNumber' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'StockNumber'}
                  direction={orderBy === 'StockNumber' ? order : 'asc'}
                  onClick={createSortHandler('StockNumber')}
                >
                  Stock #
                  {orderBy === 'StockNumber' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'Name' ? order : false}>
                <TableSortLabel active={orderBy === 'Name'} direction={orderBy === 'Name' ? order : 'asc'} onClick={createSortHandler('Name')}>
                  Name
                  {orderBy === 'Name' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'PhoneNumber' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'PhoneNumber'}
                  direction={orderBy === 'PhoneNumber' ? order : 'asc'}
                  onClick={createSortHandler('PhoneNumber')}
                >
                  Phone Number
                  {orderBy === 'PhoneNumber' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'Vehicle' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'Vehicle'}
                  direction={orderBy === 'Vehicle' ? order : 'asc'}
                  onClick={createSortHandler('Vehicle')}
                >
                  Description
                  {orderBy === 'Vehicle' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'AccountStatus' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'AccountStatus'}
                  direction={orderBy === 'AccountStatus' ? order : 'asc'}
                  onClick={createSortHandler('AccountStatus')}
                >
                  Status
                  {orderBy === 'AccountStatus' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'DealDate' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'DealDate'}
                  direction={orderBy === 'DealDate' ? order : 'asc'}
                  onClick={createSortHandler('DealDate')}
                >
                  Deal
                  {orderBy === 'DealDate' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              <TableCell sortDirection={orderBy === 'NextDueDate' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'NextDueDate'}
                  direction={orderBy === 'NextDueDate' ? order : 'asc'}
                  onClick={createSortHandler('NextDueDate')}
                >
                  Next Due
                  {orderBy === 'NextDueDate' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              <TableCell sortDirection={orderBy === 'DaysLate' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'DaysLate'}
                  direction={orderBy === 'DaysLate' ? order : 'asc'}
                  onClick={createSortHandler('DaysLate')}
                >
                  Days Late
                  {orderBy === 'DaysLate' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              <TableCell sortDirection={orderBy === 'BalancePrincipal' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'BalancePrincipal'}
                  direction={orderBy === 'BalancePrincipal' ? order : 'asc'}
                  onClick={createSortHandler('BalancePrincipal')}
                >
                  Balance Principal
                  {orderBy === 'BalancePrincipal' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'IsMidStream' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'IsMidStream'}
                  direction={orderBy === 'IsMidStream' ? order : 'asc'}
                  onClick={createSortHandler('IsMidStream')}
                >
                  Is MidStream
                  {orderBy === 'IsMidStream' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              <TableCell sortDirection={orderBy === 'BranchCode' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'BranchCode'}
                  direction={orderBy === 'BranchCode' ? order : 'asc'}
                  onClick={createSortHandler('BranchCode')}
                >
                  Branch
                  {orderBy === 'BranchCode' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(getComparator(order, orderBy), paginatedData)
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((item) => (
                <TableRow hover key={item.AccountID} sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                  <TableCell padding='checkbox'>
                    <Checkbox checked={selected.includes(item.DealID as number)} onChange={() => handleSelect(item.DealID as number)} />
                  </TableCell>
                  <TableCell onClick={() => handleEdit(item?.DealID?.toString() as string)}>{item.StockNumber}</TableCell>
                  <TableCell onClick={() => handleEdit(item?.DealID?.toString() as string)}>{item?.Name}</TableCell>
                  <TableCell onClick={() => handleEdit(item?.DealID?.toString() as string)}>{item?.PhoneNumber}</TableCell>
                  <TableCell onClick={() => handleEdit(item?.DealID?.toString() as string)}>{item?.Vehicle}</TableCell>
                  <TableCell onClick={() => handleEdit(item?.DealID?.toString() as string)}>{item?.AccountStatus}</TableCell>
                  <TableCell onClick={() => handleEdit(item?.DealID?.toString() as string)}>{convertDateToDisplay(item?.DealDate)}</TableCell>
                  <TableCell onClick={() => handleEdit(item?.DealID?.toString() as string)}>{convertDateToDisplay(item?.NextDueDate)}</TableCell>
                  <TableCell onClick={() => handleEdit(item?.DealID?.toString() as string)}>{item?.DaysLate}</TableCell>
                  <TableCell onClick={() => handleEdit(item?.DealID?.toString() as string)}>{item?.BalancePrincipal}</TableCell>
                  <TableCell onClick={() => handleEdit(item?.DealID?.toString() as string)}>{item?.IsMidStream ? 'Yes' : 'No'}</TableCell>
                  <TableCell onClick={() => handleEdit(item?.DealID?.toString() as string)}>{item?.BranchCode}</TableCell>
                </TableRow>
              ))}
            {searchAccountsData && searchAccountsData?.length === 0 && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  No results
                </TableCell>
              </TableRow>
            )}
            {loading && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ width: '100%', borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
        <Paper>
          <TablePagination
            component='div'
            count={searchAccountsData?.length as number}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  )
}

export default LoanPurchaseTableBlock
