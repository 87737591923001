import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, FormControlLabel, Grid, Switch, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { CheckboxField } from '../../../../../../ui'
import { Field, useFormState } from 'react-final-form'

const classes = generateUtilityClasses('Grid', ['root'])

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {},
}))

interface Props {}

export const InsightsForm = ({}: Props) => {
  const { values } = useFormState()
  return (
    <>
      <CardHeader title='Insights' />
      <Grid container spacing={2} padding={3}>
        <Grid item xs={3}>
          <Field name='AccessInsights'>
            {(props) => (
              <FormControlLabel
                sx={{ width: '100%' }}
                control={
                  <Switch
                    name={props.input.name}
                    checked={props.input.value}
                    onChange={(e) => {
                      props.input.onChange(e.target.checked)
                    }}
                  />
                }
                labelPlacement='end'
                label='Access Insights'
              />
            )}
          </Field>
        </Grid>
        {values.AccessInsights && (
          <>
            <Grid item xs={3}>
              <Field name='InventoryInsights'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Inventory Insights'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field name='SalesInsights'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Sales Insights'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field name='CollectionsInsights'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Collections Insights'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field name='AccountInsights'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Account Insights'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field name='PaymentInsights'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Payment Insights'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field name='ManagementInsights'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Management Insights'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field name='AccountingInsights'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Accounting Insights'
                  />
                )}
              </Field>
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

export default InsightsForm
