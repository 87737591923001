import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectDropdownByNameActiveOnly, usePurchaseAccountsMutation } from '../../../../../services'
import { DropdownCheckboxes, DropdownField, TextField } from '../../../../../ui'
import { Form } from 'react-final-form'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'

function PurchaseDialog({
  open,
  setOpen,
  selected,
  searchAccounts,
  setSelected,
}: {
  open: boolean
  setSelected: Dispatch<SetStateAction<number[]>>
  setOpen: Dispatch<SetStateAction<boolean>>
  selected: number[]
  searchAccounts: any
}) {
  const dispatch = useDispatch()
  const branch = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Branch'))
  const dealer = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Dealer'))
  const [purchaseAccounts, { data: purchaseAccountsData, isLoading: purchaseAccountsIsLoading, isSuccess, error }] = usePurchaseAccountsMutation()

  const handleSubmit = (values: any) => {
    // const jsonBranchList = values?.branch?.map((item: any) => ({ BranchID: item?.BranchID })) || []
    purchaseAccounts({
      uspAccountPurchase: {
        branchID: values.Branch,
        dealData: selected.map((el) => {
          return { ID: el }
        }),
        dealerID: values.Dealer,
        discountAmount: values.DiscountRate,
        discountRate: values.DiscountRate,
        reserveAmount1: values.ReserveAmount1,
        reserveAmount2: values.ReserveAmount2,
        reserveAmount3: values.ReserveAmount3,
        reserveAmount4: values.ReserveAmount4,
      },
    })
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(enqueueNotification({ message: 'Loan Purchased Successfully', options: { variant: 'success' } }))

      searchAccounts({
        uspAccountSearch: {
          searchString: null,
          jsonBranchList: [],
          accountStatusOption: 2,
          coaID: null,
          isFinanceTransferSearch: true,
        },
      })
      setSelected([])
      setOpen(false)
    } else {
      // dispatch(enqueueNotification({ message: 'Error in Purchasing ', options: { variant: 'success' } }))
    }
  }, [purchaseAccountsData, isSuccess])

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle>Purchase Loans</DialogTitle>
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          Branch: branch?.length == 1 ? branch[0].BranchID : null,
          Dealer: dealer?.length == 1 ? dealer[0].ID : null,
        }} // require to avoid first debounce search
      >
        {({ handleSubmit, values, form: { submit, change } }: any) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container py={1} spacing={2}>
                {branch && branch.length > 1 ? (
                  <Grid item xs={12}>
                    <DropdownFieldSearch
                      variant='outlined'
                      name='Branch'
                      label='Branch'
                      options={branch}
                      optionKey={'BranchID'}
                      optionValue={'Branch'}
                      fullWidth
                    />
                  </Grid>
                ) : (
                  <></>
                )}
                {dealer && (
                  <Grid item xs={12}>
                    <DropdownFieldSearch
                      onChange={(val) => {
                        let currDealer = dealer.find((el) => el.ID == val)
                        console.log(currDealer)
                        change('DiscountRate', currDealer?.DiscountRate)
                        change('ReserveAmount1', currDealer?.ReserveAmount1)
                        change('ReserveAmount2', currDealer?.ReserveAmount2)
                        change('ReserveAmount3', currDealer?.ReserveAmount3)
                        change('ReserveAmount4', currDealer?.ReserveAmount4)
                      }}
                      name='Dealer'
                      label='Dealer'
                      options={dealer}
                      optionKey={'ID'}
                      optionValue={'Name'}
                      fullWidth
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField fullWidth name='DiscountRate' label='Discount Rate %' />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth name='ReserveAmount1' label='Reserve Amount 1' />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth name='ReserveAmount2' label='Reserve Amount 2' />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth name='ReserveAmount3' label='Reserve Amount 3' />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth name='ReserveAmount4' label='Reserve Amount 4' />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpen(false)
                }}
                type='button'
                color='error'
                variant='contained'
              >
                Cancel
              </Button>
              <Button type='submit' variant='contained'>
                Submit
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  )
}

export default PurchaseDialog
