import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, FormControlLabel, Grid, Switch, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { CheckboxField } from '../../../../../../ui'
import { Field, useFormState } from 'react-final-form'

const classes = generateUtilityClasses('Grid', ['root'])

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {},
}))

interface Props {}

export const CreditAppsForm = ({}: Props) => {
  const { values } = useFormState()
  return (
    <>
      <CardHeader title='Leads/Credit Apps' />
      <Grid container spacing={2} padding={3}>
        <Grid item xs={3}>
          <Field name='AccessCreditApps'>
            {(props) => (
              <FormControlLabel
                sx={{ width: '100%' }}
                control={
                  <Switch
                    name={props.input.name}
                    checked={props.input.value}
                    onChange={(e) => {
                      props.input.onChange(e.target.checked)
                    }}
                  />
                }
                labelPlacement='end'
                label='Access Leads/Credit Apps'
              />
            )}
          </Field>
        </Grid>
        {values.AccessCreditApps && (
          <>
            <Grid item xs={3}>
              <Field name='AddCreditApps'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Add Leads/Credit Apps'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field name='DeleteCreditApps'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Delete Leads/Credit Apps'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field name='EditCreditApps'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Edit Leads/Credit Apps'
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field name='PullCredit'>
                {(props) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Switch
                        name={props.input.name}
                        checked={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.checked)
                        }}
                      />
                    }
                    labelPlacement='end'
                    label='Pull Credit'
                  />
                )}
              </Field>
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

export default CreditAppsForm
