import React, { ChangeEvent } from 'react'
import { default as MuiTextField } from '@mui/material/TextField'
import { Field } from 'react-final-form'
import { ReactComponent as CalculateIcon } from '../../assets/icons/calculator_icon.svg'
import { Box, Card, IconButton, Popper, Table, TableBody, TableCell, TableRow, Tooltip, createFilterOptions } from '@mui/material'
import { convertDateToDisplay, formatDate } from '../../utils/dateUtils'

interface Props {
  name: string
  label?: string
  variant?: 'standard' | 'filled' | 'outlined'
  [x: string]: any
  isCalculate?: string
  openPopover?: any
  validate?: (value: any) => any
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  data?: any[]
  setValue?: any
}

const filter = createFilterOptions<any>()

interface FilmOptionType {
  inputValue?: string
  title: string
  year?: number
  setValue: any
}
export const TextField = ({ name, variant, isCalculate, validate, onChange, openPopover, defaultValue, data, setValue, ...props }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  return (
    <>
      <Field name={name} validate={validate} defaultValue={defaultValue}>
        {({ input, meta: { error, touched } }) => {
          return (
            <MuiTextField
              {...props}
              {...input}
              variant={variant}
              error={touched && error ? true : false}
              helperText={touched && error}
              onBlur={(event) => {
                input.onBlur(event)
                setTimeout(() => {
                  setAnchorEl(null)
                }, 400)
              }}
              InputProps={{
                endAdornment: (
                  <React.Fragment>
                    {isCalculate?.toLowerCase() === 'payment' && (
                      <Tooltip title='Calculate'>
                        <IconButton onClick={openPopover}>
                          <CalculateIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </React.Fragment>
                ),
                ...props.InputProps,
                onChange: (event) => {
                  setAnchorEl(event.currentTarget)
                  input.onChange(event)
                  onChange && onChange(event)
                },
                onFocus: (event) => {
                  setAnchorEl(event.currentTarget)
                  input.onFocus(event)
                  // event.target.select()
                },
                autoComplete: 'one-time-code',
                form: {
                  autocomplete: 'off',
                },
              }}
            />
          )
        }}
      </Field>
      <Popper id={id} open={open} anchorEl={anchorEl} placement={'bottom-start'}>
        <Card>
          <Table>
            <TableBody>
              {data?.map((el) => (
                <TableRow
                  hover
                  onClick={() => {
                    setValue(el)
                    setAnchorEl(null)
                  }}
                >
                  <TableCell>{el?.Name}</TableCell>
                  <TableCell>{convertDateToDisplay(el?.DateOfBirth)}</TableCell>
                  <TableCell>{el?.phoneNumber}</TableCell>
                  <TableCell>{el?.AddressData?.[0]?.Address1 ?? el?.AddressData?.[0]?.Address1}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </Popper>
    </>
  )
}

export default TextField
