import React from 'react'
import { styled } from '@mui/system'
import { FormControlLabel, Grid, Switch } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import DropdownFieldSearch from '../../../../../../ui/DropdownFieldSearch'
import { TextField } from '../../../../../../ui'
import { Field } from 'react-final-form'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {}

export const NameForm = ({}: Props) => {
  return (
    <Grid container spacing={2} padding={3}>
      <Grid item xs={6}>
        <TextField required name='RoleName' label='Role Name' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <Field name='IsActive'>
          {(props) => (
            <div>
              <FormControlLabel
                // sx={{ m: 2, top: 0, position: 'absolute', left: 60 }}
                control={
                  <Switch
                    name={props.input.name}
                    checked={props.input.value}
                    onChange={(e) => {
                      props.input.onChange(e.target.checked)
                    }}
                  />
                }
                labelPlacement='end'
                label='Is Active?'
              />
            </div>
          )}
        </Field>
      </Grid>
    </Grid>
  )
}

export default NameForm
