import React, { useState } from 'react'
import { useSearchAccountsMutation } from '../../../services'
import { Form } from 'react-final-form'
import AccountsListSearchBlock from './components/AccountsListSearchBlock/AccountsListSearchBlock'
import AccountsListTableBlock from './components/AccountsListTableBlock/AccountsListTableBlock'
import { withPermissionsDropdown } from '../../../hoc'
import { Button, Card, Container } from '@mui/material'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import { storageGet } from '../../../utils/storage'
import LoanPurchaseSearchBlock from './components/AccountsListSearchBlock/AccountsListSearchBlock'
import LoanPurchaseTableBlock from './components/AccountsListTableBlock/AccountsListTableBlock'
import PurchaseDialog from './components/PurchaseDialog/PurchaseDialog'

interface Props {}

const LoanPurchaseList = ({}: Props) => {
  const [searchAccounts, { data: searchAccountsData, isLoading: searchAccountsIsLoading, error }] = useSearchAccountsMutation()
  const [selected, setSelected] = useState<number[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const companyType = storageGet('companyType')

  const handleSubmit = (values: any) => {
    const jsonBranchList = values?.branch?.map((item: any) => ({ BranchID: item?.BranchID })) || []
    searchAccounts({
      uspAccountSearch: {
        searchString: values?.searchString || null,
        jsonBranchList: jsonBranchList || [],
        accountStatusOption: values?.accountStatusOption || null,
        coaID: null,
        isFinanceTransferSearch: true,
      },
    })
  }

  return (
    <Container
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CustomBreadcrumbs
        heading='Accounts'
        links={[
          {
            name: 'List',
          },
        ]}
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />

      <Card
        sx={{
          display: { md: 'flex' },
          flexDirection: { md: 'column' },
        }}
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={{
            searchString: null,
            accountStatusOption: 2,
          }} // require to avoid first debounce search
        >
          {({ handleSubmit, form: { submit } }: any) => (
            <form onSubmit={handleSubmit}>
              <LoanPurchaseSearchBlock setOpen={setOpen} submit={submit} error={error} selected={selected} />
              <LoanPurchaseTableBlock
                setSelected={setSelected}
                selected={selected}
                loading={searchAccountsIsLoading}
                searchAccountsData={searchAccountsData ?? []}
              />
            </form>
          )}
        </Form>
      </Card>
      <PurchaseDialog setSelected={setSelected} searchAccounts={searchAccounts} selected={selected} setOpen={setOpen} open={open} />
    </Container>
  )
}

export default withPermissionsDropdown(LoanPurchaseList)
